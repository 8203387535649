// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-templates-esp-news-js": () => import("./../../../src/templates/esp-news.js" /* webpackChunkName: "component---src-templates-esp-news-js" */),
  "component---src-templates-list-js": () => import("./../../../src/templates/list.js" /* webpackChunkName: "component---src-templates-list-js" */),
  "component---src-templates-module-news-js": () => import("./../../../src/templates/module-news.js" /* webpackChunkName: "component---src-templates-module-news-js" */),
  "component---src-templates-program-news-js": () => import("./../../../src/templates/program-news.js" /* webpackChunkName: "component---src-templates-program-news-js" */),
  "component---src-templates-totvs-news-js": () => import("./../../../src/templates/totvs-news.js" /* webpackChunkName: "component---src-templates-totvs-news-js" */)
}

